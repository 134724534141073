// This module consoidates all the elements comprising thebasis for Exlibris apps UI
import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Settings file - we use it to check if we enable extending Angular Material
import { environment } from '../environments/environment';
// CDK overlay container - gives us acces to elements outside the app root
import { OverlayContainer } from '@angular/cdk/overlay';

// The official way of adding Angular Material icon suppoer
import { MatIconRegistry } from '@angular/material/icon';

import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
})
export class MaterialBaseModule {
    muloCoreIconsFile = 'assets/icons/mulo-core-icons.svg';
    svgIconsFile = '/esploro/assets/icons/exl-app-icons.svg';
    materialExtensionClassName = 'exl-extend-material';

    constructor(
        private sanitizer: DomSanitizer,
        private iconRegistry: MatIconRegistry,
        private overlayContainer: OverlayContainer
    ) {
        // Adds the neccessary Material extension class to the overlay container
        this.extendMaterialClass();
        // register SVG icons
        this.registerSvgIcons();
    }

    private extendMaterialClass() {
        if (environment.extendMaterial) {
            this.overlayContainer
                .getContainerElement()
                .classList.add(this.materialExtensionClassName);
        }
    }

    private registerSvgIcons() {
        const sanitizedMuloIconUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(
                this.muloCoreIconsFile
            );
        const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.svgIconsFile
        );
        this.iconRegistry.addSvgIconSet(sanitizedMuloIconUrl);
        this.iconRegistry.addSvgIconSet(sanitizedUrl);
    }
}
