<ng-template [ngIf]="media.is('gt-sm')">
  <ng-template [ngIf]="type === 'toggle'">
    <mat-slide-toggle
      [checked]="data.checked"
      (change)="actionEvent.emit($event)"
      color="primary"
      [disabled]="disabled"
      class="is-slim is-quiet has-label-below"
    >
      <ng-template [ngIf]="data.checked" [ngIfElse]="uncheckedTmpl">{{
        (labels[0] | translate) || labels[0]
      }}</ng-template>
      <ng-template #uncheckedTmpl>{{ (labels[1] | translate) || labels[1] }}</ng-template>
    </mat-slide-toggle>
  </ng-template>
  <ng-template [ngIf]="type === 'visibility'">
    <mulo-visibility-switch
      [active]="data.visible"
      (changed)="actionEvent.emit($event)"
      [visibleLabel]="labels[0]"
      [hiddenLabel]="labels[1]"
      [toolTipText]="labels[2]"
      [disabled]="disabled"
    >
    </mulo-visibility-switch>
  </ng-template>
  <ng-template [ngIf]="type === 'icon-btn'">
    <button
      mat-icon-button
      (click)="actionEvent.emit($event)"
      [attr.aria-label]="labels[1] || labels[0]"
      [disabled]="disabled"
    >
      <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon" [svgIcon]="icon"></mat-icon>
    </button>
  </ng-template>
</ng-template>
<ng-template [ngIf]="media.is('lt-md')">
  <ng-template [ngIf]="type === 'toggle'">
    <button mat-menu-item (click)="$event.stopPropagation()" [disabled]="disabled">
      <mat-icon *ngIf="icon" [class.mat-icon-rtl-mirror]="rtlMirrorIcon" [svgIcon]="icon"></mat-icon>
      <mat-slide-toggle
        [checked]="data.checked"
        (change)="actionEvent.emit($event)"
        color="primary"
        labelPosition="before"
        [disabled]="disabled"
      >
        <ng-template [ngIf]="data.checked" [ngIfElse]="uncheckedTmpl">{{
          (labels[0] | translate) || labels[0]
        }}</ng-template>
        <ng-template #uncheckedTmpl>{{ (labels[1] | translate) || labels[1] }}</ng-template>
      </mat-slide-toggle>
    </button>
  </ng-template>
  <ng-template [ngIf]="type === 'visibility'">
    <button mat-menu-item (click)="$event.stopPropagation()" [disabled]="disabled">
      <mat-icon *ngIf="icon" [class.mat-icon-rtl-mirror]="rtlMirrorIcon" [svgIcon]="icon"></mat-icon>
      <mat-slide-toggle
        [checked]="data.visible"
        (change)="actionEvent.emit($event)"
        color="primary"
        labelPosition="before"
        [disabled]="disabled"
      >
        <ng-template [ngIf]="data.visible" [ngIfElse]="hiddenTmpl">{{
          (labels[0] | translate) || labels[0]
        }}</ng-template>
        <ng-template #hiddenTmpl>{{ (labels[1] | translate) || labels[1] }}</ng-template>
      </mat-slide-toggle>
    </button>
  </ng-template>
  <ng-template [ngIf]="type === 'icon-btn'">
    <button mat-menu-item (click)="actionEvent.emit($event)" [disabled]="disabled">
      <mat-icon *ngIf="icon" [class.mat-icon-rtl-mirror]="rtlMirrorIcon" [svgIcon]="icon"></mat-icon>
      <span>{{ (labels[0] | translate) || labels[0] }}</span>
    </button>
  </ng-template>
</ng-template>
