<mulo-header #trigger="cdkOverlayOrigin" cdkOverlayOrigin>
    <div slot="header-left">
        <mulo-header-nav-item>
            <a
                [attr.aria-label]="'research.search.document.title' | translate: { value: institutionName }"
                [href]="logoUrl$ | async"
                class="header-logo-link"
            >
                <mulo-brand></mulo-brand>
            </a>
        </mulo-header-nav-item>
        <mulo-header-nav-item *ngIf="media.is('gt-sm') && displayEntityMenu" class="margin-left-md">
            <button [matMenuTriggerFor]="newMenu" class="padding-left-sm padding-right-sm" mat-button>
                <span class="menu-btn-content">
                    {{ 'research.header.entityMenuBtn' | translate }}
                    <mat-icon class="menu-icon" svgIcon="menu-down"></mat-icon>
                </span>
            </button>
            <mat-menu #newMenu="matMenu" [hasBackdrop]="true" class="new-header-menu">
                <div class="new-header-menu-grid">
                    <ng-container *ngFor="let entity of entities">
                        <a
                            *ngIf="entity.active"
                            [queryParams]="getEntityParams(entity)"
                            [routerLink]="entity.path ? entity.path : '/search/' + entity.code"
                            mat-menu-item
                        >
                            <h1>
                                {{ 'esploro.research.conf.research.portal.label.prefix.' + entity.code | translate }}
                            </h1>
                            <p>
                                {{
                                    'esploro.research.conf.research.portal.label.prefix.' + entity.code + '.description'
                                        | translate
                                }}
                            </p>
                        </a>
                    </ng-container>
                </div>
                <div class="new-header-menu-list">
                    <a
                        *ngFor="let link of headerLinks"
                        [href]="link.url"
                        [matTooltip]="link.description"
                        [target]="link.open === 'current' ? '_self' : '_blank'"
                        mat-menu-item
                    >
                        {{ 'esploro.research.conf.research.portal.label.prefix.' + link.code | translate }}
                    </a>
                </div>
            </mat-menu>
        </mulo-header-nav-item>
        <mulo-header-nav-item *ngIf="media.is('gt-sm')" [class.margin-left-md]="!displayEntityMenu" class="search-wrap">
            <mulo-searchbar
                #searchbar
                (inputChanged)="searchQuery = $event"
                (stateChange)="searchbarState = $event"
                (submit)="search($event)"
                [autocompleteSearchBy]="autocompleteSearchBy"
                [collapseBtnLabel]="'research.header.search.close' | translate"
                [inputValue]="searchQuery"
                [label]="
                    (searchEntities?.length === 1 ? searchEntities[0].placeholder : searchbarPlaceholder) | translate
                "
                [clearInputButtonLabel]="'research.aria.search.bar.clear' | translate"
                color="none"
                size="mini"
            >
                <mulo-searchbar-options
                    (selectionChange)="handleEntityChange($event)"
                    [label]="'research.aria.search.combobox.entity' | translate"
                    [options]="searchEntities"
                    [value]="activeEntity"
                    placement="after"
                ></mulo-searchbar-options>
            </mulo-searchbar>
        </mulo-header-nav-item>
        <ng-container *ngIf="headerLinksFiltered.length > 0">
            <mulo-header-links
                (itemsInMenu)="itemsInMenu = $event"
                *mediaIf="'gt-sm'"
                [aria-label]="institutionName"
                separators="dots"
            >
                <ng-container *ngFor="let link of headerLinksFiltered">
                    <mulo-header-nav-item *ngIf="link.url">
                        <a
                            [matTooltip]="link.description"
                            [href]="link.url"
                            [target]="link.open === 'current' ? '_self' : '_blank'"
                            mat-button
                        >
                            {{ 'esploro.research.conf.research.portal.label.prefix.' + link.code | translate }}
                        </a>
                    </mulo-header-nav-item>
                </ng-container>
                <button
                    [matMenuTriggerFor]="linksMenu"
                    attr.aria-label="{{ 'research.aria.icon.button.more.links' | translate }}"
                    mat-icon-button
                >
                    <mat-icon svgIcon="dots-horizontal"></mat-icon>
                </button>
                <mat-menu #linksMenu="matMenu">
                    <ng-container *ngFor="let link of headerLinks | slice: -itemsInMenu">
                        <a
                            *ngIf="link.url"
                            [href]="link.url"
                            [target]="link.open === 'current' ? '_self' : '_blank'"
                            mat-menu-item
                        >
                            {{ 'esploro.research.conf.research.portal.label.prefix.' + link.code | translate }}
                        </a>
                    </ng-container>
                </mat-menu>
            </mulo-header-links>
        </ng-container>
    </div>

    <slot-header-right>
        <ng-container>
            <mulo-header-nav>
                <mulo-header-nav-item *ngIf="includePivot && isResearcherLoggedIn() && media.is('gt-sm')">
                    <a [queryParams]="{ institution: institutionCode }" [routerLink]="['/funding']" mat-button>{{
                        'research.header.pivot.funding.button' | translate
                    }}</a>
                </mulo-header-nav-item>
                <mulo-header-nav-item *ngIf="isResearcherLoggedIn() && media.is('gt-sm') && contentEntities.length">
                    <button
                        #overlayTrigger
                        [gleam]="false"
                        [jellyOnHover]="true"
                        [matMenuTriggerFor]="contentMenu"
                        class="content-menu-btn"
                        mulo-funky-button
                        role="button"
                    >
                        <mat-icon svgIcon="plus"></mat-icon>
                        <span>{{ 'research.header.new.deposit.button' | translate }}</span>
                        <mat-icon class="menu-down-icon" svgIcon="menu-down"></mat-icon>
                    </button>
                    <ng-container espDepositFormTrigger (overlayClosed)="overlayTrigger.focus()"></ng-container>
                    <mat-menu #contentMenu="matMenu">
                        <ng-container *ngFor="let item of contentEntities">
                            <button (click)="menuContentOnClick(item)" *ngIf="item.active" mat-menu-item>
                                {{ 'research.header.content.menu.' + item.code | translate }}
                            </button>
                        </ng-container>
                    </mat-menu>
                </mulo-header-nav-item>
                <mulo-header-nav-item *ngIf="displayLanguageButton()">
                    <mulo-language-select-button
                        (languageChange)="handleLanguageChange($event)"
                        [ariaLabel]="'research.header.language.dialog.title' | translate"
                        [cancelLabel]="'research.header.language.dialog.close' | translate"
                        [langListLabel]="'research.header.language.dialog.title' | translate"
                        [languages]="languages"
                        [mode]="media.is('lt-md') ? 'icon' : 'compact'"
                    ></mulo-language-select-button>
                </mulo-header-nav-item>
                <mulo-header-nav-item *ngIf="isResearcherLoggedIn() && notifyCount > 0" class="notify-menu">
                    <mulo-notify-bell
                        [ariaLabel]="'research.header.outputClaim.a11yLabel' | translate: { count: notifyCount }"
                        [count]="notifyCount"
                        [triggerFor]="notifyMenu"
                    ></mulo-notify-bell>
                    <mat-menu #notifyMenu="matMenu" xPosition="before">
                        <div class="notify-menu-title">{{ 'research.header.outputClaim.title' | translate }}</div>
                        <div
                            [innerHTML]="
                                'research.header.outputClaim.infobar'
                                    | translate: { count: '<em>' + notifyCount + '</em>' }
                            "
                            class="notify-menu-infobar"
                        ></div>
                        <ng-container *ngFor="let output of outputsForNotificationDisplay; let i = index">
                            <mulo-brief
                                [hoverable]="false"
                                [titleCutoff]="true"
                                [title]="output.title"
                                class="notify-menu-item"
                                type="{{ output.resourceType | translate }}"
                            >
                                <div *ngIf="output.creators?.length === 1" class="notify-authors">
                                    {{ output.creators }}
                                </div>
                                <div *ngIf="output.creators?.length > 1" class="notify-authors">
                                    {{ output.creators.slice(0, -1).join(', ') + ' and ' + output.creators.slice(-1) }}
                                </div>
                                <div class="notify-time">{{ output.dateObject | amTimeAgo }}</div>
                            </mulo-brief>
                        </ng-container>
                        <button
                            [queryParams]="{ institution: institutionCode }"
                            class="notify-menu-c2a mat-flat-button mat-button-base mat-primary"
                            mat-menu-item
                            routerLink="profile/output/claim"
                        >
                            {{ 'research.header.outputClaim.callToAction' | translate }}
                        </button>
                    </mat-menu>
                </mulo-header-nav-item>

                <mulo-header-nav-item *ngIf="media.is('lt-md')">
                    <button
                        #mobileSearchBtn
                        (click)="handleMobileSearchBtnClick($event)"
                        [attr.aria-label]="'research.header.search.placeholder' | translate"
                        [disabled]="mobileSearchMenuOpen"
                        mat-icon-button
                        [attr.aria-expanded]="mobileSearchMenuOpen"
                    >
                        <mat-icon svgIcon="magnify"></mat-icon>
                    </button>
                </mulo-header-nav-item>

                <mulo-header-nav-item *ngIf="isResearcherLoggedIn()">
                    <exl-user-nav>
                        <exl-user-menu
                            (action)="onUserMenuAction($event)"
                            (signOut)="doSignOut()"
                            [exlCommonOnboarding]="false"
                            [menuItems]="menuItems"
                            [menuStyle]="media.is('gt-sm') ? 'menu' : 'sheet'"
                            [proxyImage]="proxyAvatarImage"
                            [proxyInitials]="proxyInitials"
                            [proxyName]="proxyName"
                            [showArrow]="media.is('gt-sm')"
                            [userImage]="userAvatarImage"
                            [userInitials]="userInitials"
                            [userName]="userName"
                            [ariaLabel]="'research.header.userMenu.btn.ariaLabel' | translate"
                            class="margin-left-tiny"
                            exlCommonOnboardingAlign="right"
                            exlCommonOnboardingScale=".5"
                            exlCommonOnboardingText="research.header.proxy.menu.message"
                        >
                        </exl-user-menu>
                    </exl-user-nav>
                </mulo-header-nav-item>
                <ng-container *ngIf="includeSignIn && !isLoggedIn()">
                    <mulo-header-nav-item *mediaIf="'gt-sm'">
                        <a
                            [queryParams]="{ institution: institutionCode }"
                            attr.aria-label="{{ 'research.aria.sign.in' | translate }}"
                            mat-button
                            routerLink="/login"
                        >
                            {{ 'research.header.sign.in' | translate }}
                        </a>
                    </mulo-header-nav-item>
                    <mulo-header-nav-item *mediaIf="'lt-md'">
                        <button
                            [queryParams]="{ institution: institutionCode }"
                            attr.aria-label="{{ 'research.aria.sign.in' | translate }}"
                            mat-icon-button
                            routerLink="/login"
                        >
                            <mat-icon class="mat-icon-rtl-mirror" svgIcon="login"></mat-icon>
                        </button>
                    </mulo-header-nav-item>
                </ng-container>
                <ng-container>
                    <mulo-header-nav-item *ngIf="isUserLoggedIn()">
                        <a (click)="doSignOut()" [attr.aria-label]="'research.header.sign.out' | translate" mat-button>
                            <span *mediaIf="'gt-sm'">{{ 'research.header.sign.out' | translate }}</span>
                            <mat-icon *mediaIf="'lt-md'" class="mat-icon-rtl-mirror" svgIcon="logout"></mat-icon>
                        </a>
                    </mulo-header-nav-item>
                </ng-container>
                <mulo-header-nav-item *mediaIf="'lt-md'">
                    <mulo-menu-toggle-button
                        (clicked)="toggleSidenav()"
                        [state]="sidenavService.isOpen ? 'open' : 'closed'"
                        class="header-menu-toggle-btn"
                        [openText]="'research.header.entityMenuBtn.open' | translate"
                        [closeText]="'research.header.entityMenuBtn.close' | translate"
                    ></mulo-menu-toggle-button>
                </mulo-header-nav-item>
            </mulo-header-nav>
        </ng-container>
    </slot-header-right>
</mulo-header>
<ng-container *mediaIf="'lt-md'">
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="mobileSearchMenuOpen"
        [cdkConnectedOverlayFlexibleDimensions]="true"
        [cdkConnectedOverlayPush]="true"
        [cdkConnectedOverlayGrowAfterOpen]="true"
        cdkConnectedOverlayMinWidth="100vw"
        (detach)="handleMobileSearchBtnClose()"
        (overlayOutsideClick)="handleMobileSearchBtnClose()"
    >
        <mat-accordion class="mobile-search-menu mat-elevation-z8" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
            <mat-expansion-panel [expanded]="mobileSearchMenuOpen">
                <div class="mobile-search-menu-options">
                    <mulo-searchbar-options
                        (selectionChange)="handleEntityChange($event)"
                        [label]="'research.aria.search.combobox.entity' | translate"
                        [options]="searchEntities"
                        [value]="activeEntity"
                    ></mulo-searchbar-options>
                    <mulo-searchbar-options
                        (selectionChange)="handleScopeChange($event)"
                        *ngIf="searchScopesByEntity.length > 0"
                        [label]="'research.aria.search.combobox.scope' | translate"
                        [options]="searchScopesByEntity"
                        [value]="activeScope"
                        class="scope-separator"
                        id="header-scopes"
                    ></mulo-searchbar-options>
                    <button (click)="handleMobileSearchBtnClose()" class="collapse-btn" color="primary" mat-button>
                        {{ 'research.header.search.close' | translate }}
                    </button>
                </div>
                <mulo-searchbar
                    #mobileSearchbar
                    (inputChanged)="searchQuery = $event"
                    (submit)="search($event)"
                    [inputValue]="searchQuery"
                    [label]="
                        (searchEntities?.length === 1 ? searchEntities[0].placeholder : searchbarPlaceholder)
                            | translate
                    "
                    color="none"
                    size="small"
                    [clearInputButtonLabel]="'research.aria.search.bar.clear' | translate"
                ></mulo-searchbar>

                <button
                    *ngIf="advancedSearchEnabled"
                    (click)="toggleAdvSearchSidenav()"
                    class="adv-search-btn"
                    color="primary"
                    mat-button
                >
                    {{ 'research.advanced.search.title' | translate }}
                </button>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-template>
</ng-container>
<ng-template #advSearchBlock>
    <esp-advanced-search (advancedSearch)="runAdvancedSearch($event)"></esp-advanced-search>
</ng-template>

<ng-template #headerMenu>
    <mat-nav-list class="sidebar-nav-list">
        <ng-container *ngIf="displayEntityMenu">
            <ng-container *ngFor="let entity of entities">
                <a
                    (click)="toggleSidenav()"
                    *ngIf="entity.active"
                    [queryParams]="getEntityParams(entity)"
                    [routerLink]="entity.path ? entity.path : '/search/' + entity.code"
                    mat-list-item
                >
                    {{ 'esploro.research.conf.research.portal.label.prefix.' + entity.code | translate }}
                </a>
            </ng-container>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngFor="let link of headerLinks">
            <a
                (click)="toggleSidenav()"
                [href]="link.url"
                [target]="link.open === 'current' ? '_self' : '_blank'"
                mat-list-item
            >
                {{ 'esploro.research.conf.research.portal.label.prefix.' + link.code | translate }}
            </a>
        </ng-container>
        <ng-container *ngIf="includePivot && isResearcherLoggedIn()">
            <mat-divider></mat-divider>
            <a
                (click)="toggleSidenav()"
                [queryParams]="{ institution: institutionCode }"
                [routerLink]="['/funding']"
                mat-list-item
                >{{ 'research.header.pivot.funding.button' | translate }}</a
            >
        </ng-container>
    </mat-nav-list>
</ng-template>
