<div class="drag-grip"></div>
<div *ngIf="hidden" class="hidden-indicator"></div>
<span class="item-list-icon" *ngIf="extension && useListIcon" matListItemIcon>
  <mat-icon [svgIcon]="type | fileTypeIcon : true : extension"></mat-icon>
  <span>{{ extension }}</span>
</span>
<div class="mdc-list-item__content">
  <mulo-status-tag *ngIf="hidden">{{ hiddenLabel }}</mulo-status-tag>
  <ng-content select="[matListItemTitle]"></ng-content>
  <ng-content select="[mat-line], [matLine], [matListItemLine]"></ng-content>

  <ng-template matMenuContent #actionsBlock>
    <ng-content select="mulo-action-list-item-action"></ng-content>
    <mulo-action-list-item-action
      *ngIf="removable"
      type="icon-btn"
      [icon]="media.is('gt-sm') ? 'close' : null"
      [labels]="[fileRemoveTip]"
      (actionEvent)="onRemove($event, index)"
    ></mulo-action-list-item-action>
  </ng-template>

  <div class="list-item-wrap">
    <div class="list-item-header">
      <!-- list number -->
      <span class="item-index" *ngIf="index">{{ index }}. </span>
      <span class="item-name">
        <!-- files -->
        <ng-content select="[slot='file-info']"></ng-content>
        <ng-container *ngIf="!fileInfoTemplate">
          <span class="item-basename semibold" *ngIf="baseName ?? name as itemName">
            <span *ngIf="!url" [matTooltip]="itemName">{{ itemName }}</span>
            <a *ngIf="url" [href]="url" [target]="urlTarget" [matTooltip]="itemName">{{ itemName }}</a>
          </span>
          <span class="item-ext" *ngIf="extension && !useListIcon">
            <mat-icon [svgIcon]="type | fileTypeIcon : true : extension"></mat-icon>
            <span>{{ extension }}</span>
          </span>
          <span class="item-size" *ngIf="size">{{ size | fileSize }}</span>
        </ng-container>
      </span>

      <span class="item-actions">
        <ng-content select="[mulo-file-action]"></ng-content>

        <ng-container *mediaIf="'gt-sm'">
          <ng-container *ngTemplateOutlet="actionsBlock"></ng-container>
        </ng-container>
        <span class="progress-count" *ngIf="progress">
          <ng-container *ngIf="progress < 100; else doneUpload">
            <mat-icon svgIcon="upload-arrow"></mat-icon>
            <span class="semibold">{{ progress | percent }}</span>
          </ng-container>
          <ng-template #doneUpload>
            <mat-icon svgIcon="check" [matTooltip]="doneUploadMsg" class="mulo-color-positive"></mat-icon>
          </ng-template>
        </span>
        <a
          mat-icon-button
          [href]="'#'"
          [matTooltip]="filePreviewTip"
          target="_blank"
          *ngIf="progress && progress >= 100"
        >
          <mat-icon svgIcon="eye-outline"></mat-icon>
        </a>
      </span>

      <mat-progress-bar *ngIf="progress && progress < 100" [bufferValue]="0" mode="buffer" [value]="progress">
      </mat-progress-bar>
    </div>
    <div class="list-item-content" #content>
      <!-- content that appears in the opened drawer -->
      <ng-content></ng-content>
    </div>
  </div>
</div>

<ng-container *mediaIf="'lt-md'">
  <button
    mat-icon-button
    [matMenuTriggerFor]="actionsMenu"
    *ngIf="actions.length > 0 || removable"
    [attr.aria-label]="moreBtnLabel"
  >
    <mat-icon svgIcon="dots-vertical"></mat-icon>
  </button>
  <mat-menu #actionsMenu="matMenu" xPosition="before">
    <ng-container *ngTemplateOutlet="actionsBlock"></ng-container>
  </mat-menu>
</ng-container>
<ng-content select="[matListItemMeta]"></ng-content>

<ng-content select="mat-divider"></ng-content>

<!--
  Strong focus indicator element. MDC uses the `::before` pseudo element for the default
  focus/hover/selected state, so we need a separate element.
-->
<div class="mat-mdc-focus-indicator"></div>
