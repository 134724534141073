<mulo-centered-block-layout width="full">
    <h2>Colors</h2>
    @for (color of colors; track $index) {
        @if (['primary', 'accent', 'warn'].includes(color.key)) {
            <h3 class="color-row bg-{{ color.key }}-500">{{ color.label }} ({{ color.value }})</h3>
            <div class="color-row">
                @for (i of [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9]; track i) {
                    <div class="bg-{{ color.key }}-{{ i * 100 }}">{{ i * 100 }}</div>
                }
            </div>
        } @else if (color.value) {
            <h3 class="color-row bg-{{ color.key }}">{{ color.label }} ({{ color.value }})</h3>
        }
    }

    <h2>Font Sizing</h2>
    @for (i of [6, 5, 4, 3, 2, 1, 0, -1, -2, -3]; track i) {
        <div class="font-size-{{ i }}">Font Size {{ i }}</div>
    }

    <h2>Icons</h2>
    <div class="icons">
        @for (icon of iconNames; track icon) {
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-subtitle>{{ icon }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content class="margin-top-xs">
                    <mat-icon muloSvgViewbox [svgIcon]="icon"></mat-icon>
                </mat-card-content>
            </mat-card>
        }
    </div>
</mulo-centered-block-layout>
