<mat-form-field [class]="formFieldClass" [hideRequiredMarker]="hideRequiredMarker">
  <mat-label>{{label}}</mat-label>
  <input
    #inputField
    matInput
    [formControl]="dateInputCtrl"
    [required]="required"
    (change)="dateInputChangeHandler($event)"
    [errorStateMatcher]="matcher"
  />
  <ng-content select="[matPrefix]"></ng-content>
  <mat-hint>{{hint}}</mat-hint>
  <mat-error>
    <ng-container *ngIf="dateInputCtrl.hasError('externalError')">
      {{error}}
    </ng-container>
    <ng-container *ngIf="dateInputCtrl.hasError('dateFormatInvalid')">
      {{dateFormatInvalidLabel}}
    </ng-container>
  </mat-error>
  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker
    #dp
    startView="multi-year"
    (yearSelected)="chosenYearHandler($event)"
    (monthSelected)="chosenMonthHandler($event)"
    panelClass="datepicker__panel"
  >
    <input
      matInput
      [matDatepicker]="dp"
      [formControl]="dateCtrl"
      (dateChange)="dateChangeHandler($event)"
    />
  </mat-datepicker>
</mat-form-field>
