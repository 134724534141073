import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  AfterContentInit,
} from '@angular/core';
import { ExtendedFile } from '../../../models/extended-file.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  componentDestroyed,
  MediaIfDirective,
  MediaService,
} from '@exl-ng/mulo-core';
import {
  ActionListItemActionComponent,
  ActionListItemComponent,
} from '../../action-list-item';
import { MatListItemIcon } from '@angular/material/list';
import { takeUntil } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { FileSizePipe } from '../../../pipes/file-size.pipe';
import { FileTypeIconPipe } from '../../../pipes/file-type-icon.pipe';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIconAnchor, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { ActionListItemActionComponent as ActionListItemActionComponent_1 } from '../../action-list-item/action-list-item-action/action-list-item-action.component';
import {
  MatMenu,
  MatMenuContent,
  MatMenuTrigger,
} from '@angular/material/menu';
import { StatusTagComponent } from '../../status-tag/status-tag.component';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgTemplateOutlet, PercentPipe } from '@angular/common';

@Directive({
  selector: '[slot="file-info"]',
  standalone: true,
})
export class FileListItemInfoDirective {}

@Component({
  selector: 'mulo-file-list-item',
  templateUrl: './file-list-item.component.html',
  styleUrls: ['./file-list-item.component.scss'],
  host: {
    class: 'mulo-file-list-item',
    '[class.is-hover]': 'showOnHover',
    '[class.has-actions]': 'actions.length > 0 || removable',
  },
  standalone: true,
  imports: [
    NgIf,
    MatListItemIcon,
    MatIcon,
    StatusTagComponent,
    MatMenuContent,
    ActionListItemActionComponent_1,
    MatTooltip,
    MediaIfDirective,
    NgTemplateOutlet,
    MatIconAnchor,
    MatProgressBar,
    MatIconButton,
    MatMenuTrigger,
    MatMenu,
    PercentPipe,
    FileTypeIconPipe,
    FileSizePipe,
  ],
})
export class FileListItemComponent
  extends ActionListItemComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  @Input() item: ExtendedFile;
  /** Full file name (basename + extension) */
  @Input() name: string;
  /** Base file name (without extension) */
  @Input() baseName: string;
  /** File type (MIME) */
  @Input() type: string;
  /** File size in bytes */
  @Input() size: number;
  /** file extension */
  @Input() extension: string;
  /** Whether the file item is removeable */
  @Input() removable = false;
  /** Index number to show before the file name */
  @Input() index: number | null = null;
  /** Whether the file content is expandable */
  /** Title URL */
  @Input() url: null | string = null;
  /** URL target */
  @Input() urlTarget = '_blank';
  /** toggle the expanded state */
  @HostBinding('class.is-expanded') @Input() expanded = false;
  /** Whether the panel is expandable (similar to disabled, but only on the expanding container, since 'disable' prevent access to content in the panel header) */
  @Input() expandable = true;
  @Input() listStyle: 'accordion' | 'list' = 'accordion';
  @Input() useListIcon = false;
  @Input() showOnHover = false;

  @Input() progress: number | null = null;
  @Input() doneUploadMsg = 'Done uploading!';
  @Input() filePreviewTip = 'Preview this file';
  @Input() fileRemoveTip = 'Remove this file';
  @Output() remove: EventEmitter<any> = new EventEmitter();
  @ContentChild(FileListItemInfoDirective) fileInfoTemplate;
  @ViewChild(MatExpansionPanel, { static: true }) panel: MatExpansionPanel;
  @ViewChild('content', { static: true }) content: ElementRef;
  @ContentChildren(ActionListItemActionComponent) actions;

  elementHeight = '48px';

  constructor(
    public media: MediaService,
    _element: ElementRef<HTMLElement>,
    _ngZone: NgZone,
    _platform: Platform,
  ) {
    super(_element, _ngZone, _platform);
  }

  ngOnInit() {
    // Listen to changes on the panel
    this.panel?.expandedChange
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (this.expandable) {
          this.expanded = state;
        } else {
          this.panel.close();
        }
      });
  }

  ngAfterContentInit() {
    // Check for presence of projected content in the drawer and set expandability of the drawe accordingly
    if (!this.content?.nativeElement.children.length) {
      this.expandable = false;
    }
  }

  ngOnDestroy() {}

  onRemove(event, index) {
    event.preventDefault();
    event.stopPropagation();
    this.remove.emit(index);
  }

  public expand() {
    this.expanded = true;
  }
  public collapse() {
    this.expanded = false;
  }
}

@Directive({
  selector: '[mulo-file-action]',
  standalone: true,
})
export class FileActionDirective {
  @HostBinding('class.mulo-file-action') class = true;
  @HostListener('click', ['$event']) onClick($event: Event) {
    $event.stopPropagation();
  }
  @HostListener('keydown', ['$event']) onKeydown(event) {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.stopPropagation();
    }
  }
}
